/* You can add global styles to this file, and also import other style files */
header .main-navbar .nav-menu > li > a {
  padding-right: 2rem;
}

.img-white {
  filter: invert(100%) sepia(13%) saturate(7491%) hue-rotate(179deg) brightness(115%) contrast(97%);
}

.img-categories {
  height: 203px;
}

/** added by leo 03-01-2023 */
.product-box .cart-info.cart-wrap, .product-box .cart-wrap.cart-wrap, .product-wrap .cart-info.cart-wrap, .product-wrap .cart-wrap.cart-wrap {
  background: whitesmoke;
}

.pl-1 {
  padding-left: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-2::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: darkgray;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-2 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-2::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: darkgray;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-2 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-2 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-2::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right-2::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-2::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-2::after {
    left: 31px;
  }
  /* Full-width containers */
  .timeline-2 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }
  /* Make sure that all arrows are pointing leftwards */
  .timeline-2::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  /* Make sure all circles are at the same spot */
  .left-2::after,
  .right-2::after {
    left: 18px;
  }
  .left-2::before {
    right: auto;
  }
  /* Make all right containers behave like the left ones */
  .right-2 {
    left: 0%;
  }
}
.card-body {
  border-style: solid;
  border-radius: 25px;
}

.card {
  border-radius: 25px;
}

.tl-center {
  background: #282f58;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 16px #f3c415;
  justify-content: center;
  padding-top: 30px;
}

.border-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-solid-danger {
  padding: 13px 29px;
  color: #ffffff !important;
  letter-spacing: 0.05em;
  border: 2px solid var(--theme-deafult) !important;
  background-image: linear-gradient(30deg, #dc3545 50%, transparent 50%) !important;
  background-size: 850px;
  background-repeat: no-repeat;
  background-position: 0;
  transition: background 300ms ease-in-out;
}

.warning {
  border: 2px solid #ffc107 !important;
  background-image: linear-gradient(30deg, #ffc107 50%, transparent 50%) !important;
}

.blue-solid {
  border: 2px solid rgba(43, 63, 203, 0.9215686275) !important;
}

.blue-solid:hover, .blue-solid:focus {
  background-color: #3b41ff !important;
}

.success-solid {
  border: 2px solid rgba(55, 224, 117, 0.92) !important;
}

.success-solid:hover, .success-solid:focus {
  background-color: rgba(55, 224, 117, 0.92) !important;
}

.border-default {
  border: 2px solid darkgray !important;
}

.card-body {
  border-style: none;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  border-radius: 0.25rem !important;
  height: inherit !important;
  margin-bottom: 0.5rem !important;
}

/**
added 14-01-2023
 */
/**
added 17-01-2023
 */
.cart_qty_cls {
  top: 10%;
}

.fs-14 {
  font-size: 14px !important;
}

.brand-logo {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.brand-logo img {
  height: 4em;
}

.brand-logo {
  margin-right: 0;
}

.main-menu .menu-right .icon-nav .mobile-setting, .main-menu .menu-right .icon-nav .mobile-cart, .main-menu .menu-right .icon-nav .mobile-search {
  bottom: 0;
}

.modal-2 .modal-xl .modal-content {
  height: 80vh !important;
  width: 80vw !important;
}

@media (max-width: 577px) {
  .main-menu .menu-left .navbar {
    padding: 0.5rem 1rem 0.5rem 1rem !important;
  }
}
@media (min-width: 1200px) {
  .form-mobile {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .form-mobile {
    width: auto;
    margin-bottom: 0.5rem;
    display: block !important;
  }
}
.whatsapp-container {
  position: fixed;
  bottom: 2rem;
  right: 10px;
  margin: 20px;
  z-index: 9999;
  cursor: pointer;
}

.img-whatsapp {
  width: 3rem;
  border-radius: 100%;
}